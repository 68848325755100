import Pepperoni from "../assets/5.jpeg";
import Margherita from "../assets/1.jpeg";
import PedroTechSpecial from "../assets/background.png";
// import Vegan from "../assets/background.png";
// import Pineapple from "../assets/pineapple.jpg";
// import Expensive from "../assets/expensive.jpg";

export const MenuList = [
  // {
  //   name: "Herbal Products",
  //   image: Pepperoni,
  //   // price: 
  // },
  // {
  //   name: "Detoxifiers",
  //   image: Margherita,
  //   // price: 11.99,
  // },
  {
    name: "Book a Consultation",
    image: PedroTechSpecial,
    // price: 256.53,
  },
  // {
  //   name: "Vegan Pizza",
  //   // image: Vegan,
  //   // price: 17.99,
  // },
  // {
  //   name: "Pineapple Pizza",
  //   // image: Pineapple,
  //   price: 4.99,
  // },
  // {
  //   name: "Very Expensive Pizza",
  //   // image: Expensive,
  //   price: 1997.99,
  // },
];
